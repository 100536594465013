var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.$t("liveImportStep2.pageTitle"), items: _vm.items },
      }),
      !this.isLoading && !this.isSuccess
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.isError,
                      expression: "this.isError",
                    },
                  ],
                  staticClass: "row justify-content-center mb-4",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      this.isError
                        ? _c(
                            "b-alert",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                show: "",
                                variant: "danger",
                                dismissible: "",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("liveImportStep2.error.text")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                [
                  _c("div", { staticClass: "row justify-content-center" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-10" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "card-title",
                                  attrs: { for: "totalSurveyDuration" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "liveImportStep2.startingQuestion.enableCheckbox"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-2 text-right" },
                              [
                                _c("b-form-checkbox", {
                                  attrs: {
                                    id: "totalSurveyDuration",
                                    name: "check-button",
                                    switch: "",
                                  },
                                  on: {
                                    input: _vm.clickEnableTotalSurveyDuration,
                                  },
                                  model: {
                                    value: _vm.enableTotalSurveyDuration,
                                    callback: function ($$v) {
                                      _vm.enableTotalSurveyDuration = $$v
                                    },
                                    expression: "enableTotalSurveyDuration",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._l(this.pages, function (page, pageIndex) {
                    return _c(
                      "div",
                      {
                        key: pageIndex,
                        staticClass: "row justify-content-center",
                      },
                      [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "div",
                            {
                              staticClass: "card",
                              style: [
                                page.isPageSaved
                                  ? { background: "#edfaee" }
                                  : { background: "#ffffff" },
                              ],
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "card-body" },
                                [
                                  _c("div", { staticClass: "row mb-3" }, [
                                    _c("div", { staticClass: "col-md-5" }, [
                                      _c("div", { staticClass: "card-title" }, [
                                        !page.isEnableToEditPageTitle
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "hover-on-page-title",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickEditPageTitle(
                                                      pageIndex
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(page.pageTitle) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        page.isEnableToEditPageTitle &&
                                        !page.isPageSaved
                                          ? _c("span", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: page.pageTitle,
                                                    expression:
                                                      "page.pageTitle",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  name: "pageTitle",
                                                  placeholder: _vm.$t(
                                                    "liveImportStep2.form.pageName.placeholder"
                                                  ),
                                                },
                                                domProps: {
                                                  value: page.pageTitle,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.changePageName(
                                                      pageIndex,
                                                      $event
                                                    )
                                                  },
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      page,
                                                      "pageTitle",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              page.pageTitleRequiredError
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "liveImportStep2.form.pageName.requiredError"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              page.pageTitleNotUniqueError
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "liveImportStep2.form.pageName.notUniqueError"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              page.pageTitleFormatError
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "liveImportStep2.form.pageName.formatError"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm.liveDatafileMetadata
                                          .integrationName === "Forsta Plus"
                                          ? _c("span", [
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "b-popover",
                                                    rawName:
                                                      "v-b-popover.hover",
                                                    value:
                                                      _vm.propoverMethodForstaPageName,
                                                    expression:
                                                      "\n                            propoverMethodForstaPageName\n                          ",
                                                    modifiers: { hover: true },
                                                  },
                                                ],
                                                staticClass:
                                                  "ml-2 bx bxs-info-circle text-info",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                              }),
                                            ])
                                          : _vm._e(),
                                        page.isPageSaved
                                          ? _c("i", {
                                              staticClass:
                                                "ml-2 bx bxs-check-circle text-success bx-tada",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6" },
                                      [
                                        !(
                                          _vm.liveDatafileMetadata
                                            .integrationName === "Forsta Plus"
                                        ) &&
                                        _vm.enableTotalSurveyDuration &&
                                        pageIndex === 0
                                          ? _c(
                                              "b-form-checkbox",
                                              {
                                                attrs: {
                                                  name: "check-button",
                                                  disabled:
                                                    page.isPageSaved ||
                                                    (_vm.disableIsInitialPageForOtherPages &&
                                                      !page.isInitialPage),
                                                },
                                                model: {
                                                  value: page.isInitialPage,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      page,
                                                      "isInitialPage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "page.isInitialPage",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "liveImportStep2.form.initialPageCheckBox.label"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _c("small", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "liveImportStep2.form.initialPageCheckBox.description"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-1 text-right" },
                                      [
                                        page.isPageSaved &&
                                        !_vm.onePageIsOpenForEdit
                                          ? _c("i", {
                                              staticClass:
                                                "bx bxs-message-square-edit text-warning pointer",
                                              staticStyle: {
                                                "font-size": "25px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickEditPage(
                                                    pageIndex
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        !page.isPageSaved
                                          ? _c("i", {
                                              staticClass:
                                                "bx bxs-message-square-x text-danger pointer ml-2",
                                              staticStyle: {
                                                "font-size": "25px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickRemovePage(
                                                    pageIndex
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                  _vm._l(
                                    page.qualityChecks,
                                    function (qualityCheck, qualityCheckIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: qualityCheckIndex,
                                          staticClass: "p-3 mb-5",
                                          staticStyle: {
                                            border: "1px solid #e3e3e3",
                                            "border-radius": "4px",
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-12 text-right",
                                              },
                                              [
                                                !page.isPageSaved
                                                  ? _c("i", {
                                                      staticClass:
                                                        "bx bxs-x-circle text-danger",
                                                      staticStyle: {
                                                        "font-size": "25px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clickRemoveQualityCheck(
                                                            pageIndex,
                                                            qualityCheckIndex
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      { attrs: { for: "" } },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "liveImportStep2.form.datapointType.label"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                      ]
                                                    ),
                                                    page.isPageSaved
                                                      ? _c(
                                                          "select",
                                                          {
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              background:
                                                                "#f5f5f5",
                                                              "-webkit-appearance":
                                                                "none",
                                                              "-moz-appearance":
                                                                "none",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getTranslationByName(
                                                                        qualityCheck.qualityCheckType,
                                                                        _vm.qualityScoreTypes
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _c("multiselect", {
                                                          attrs: {
                                                            options:
                                                              qualityCheck.qualityCheckTypeOptions,
                                                            placeholder: _vm.$t(
                                                              "liveImportStep2.form.datapointType.placeholder"
                                                            ),
                                                            "custom-label":
                                                              _vm.columnTypeOptions,
                                                            "track-by": "name",
                                                            searchable: false,
                                                            label: "name",
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectQuestionType(
                                                                pageIndex,
                                                                qualityCheckIndex
                                                              )
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "singleLabel",
                                                                fn: function ({
                                                                  option,
                                                                }) {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.getTranslationByKey(
                                                                            option.name
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              qualityCheck.qualityCheckTypeObject,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                qualityCheck,
                                                                "qualityCheckTypeObject",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "qualityCheck.qualityCheckTypeObject",
                                                          },
                                                        }),
                                                    qualityCheck.qualityCheckTypeRequiredError
                                                      ? _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "liveImportStep2.form.datapointType.requiredError"
                                                                  )
                                                                )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]),
                                          qualityCheck.qualityCheckType ===
                                          "OPEN_ENDED"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "row mb-3 mt-3",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-4" },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            name: "check-button",
                                                            switch: "",
                                                            id:
                                                              "wrongTopic" +
                                                              pageIndex,
                                                            disabled:
                                                              page.isPageSaved,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.isEnableWrongTopicCheck,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.isEnableWrongTopicCheck =
                                                                $$v
                                                            },
                                                            expression:
                                                              "isEnableWrongTopicCheck",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-600",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "liveImportStep2.form.wrongTopic.checkBox"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("span", [
                                                            _c("i", {
                                                              directives: [
                                                                {
                                                                  name: "b-popover",
                                                                  rawName:
                                                                    "v-b-popover.hover",
                                                                  value:
                                                                    _vm.propoverMethodWrongTopicEnable,
                                                                  expression:
                                                                    "\n                              propoverMethodWrongTopicEnable\n                            ",
                                                                  modifiers: {
                                                                    hover: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "ml-2 bx bxs-info-circle text-info",
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                            }),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-4" },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            name: "check-button",
                                                            switch: "",
                                                            id:
                                                              "duplicateChecks" +
                                                              pageIndex,
                                                            disabled:
                                                              page.isPageSaved,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.enableDuplicateChecks,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.enableDuplicateChecks =
                                                                $$v
                                                            },
                                                            expression:
                                                              "enableDuplicateChecks",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-weight-600",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "liveImportStep2.form.duplicateChecks.checkBox"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("span", [
                                                            _c("i", {
                                                              directives: [
                                                                {
                                                                  name: "b-popover",
                                                                  rawName:
                                                                    "v-b-popover.hover",
                                                                  value:
                                                                    _vm.popoverMethodDuplicateChecks,
                                                                  expression:
                                                                    "\n                              popoverMethodDuplicateChecks\n                            ",
                                                                  modifiers: {
                                                                    hover: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "ml-2 bx bxs-info-circle text-info",
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                            }),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.liveDatafileMetadata
                                                    .integrationName ===
                                                  "Forsta Plus"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-4",
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              attrs: {
                                                                name: "check-button",
                                                                switch: "",
                                                                id:
                                                                  "copyPaste" +
                                                                  pageIndex,
                                                                disabled:
                                                                  page.isPageSaved,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.enableCopyPasteCheck,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.enableCopyPasteCheck =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "enableCopyPasteCheck",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-600",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "liveImportStep2.form.copyPaste.checkBox"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _c("i", {
                                                                  directives: [
                                                                    {
                                                                      name: "b-popover",
                                                                      rawName:
                                                                        "v-b-popover.hover",
                                                                      value:
                                                                        _vm.popOverMethodCopyPastes,
                                                                      expression:
                                                                        "\n                              popOverMethodCopyPastes\n                            ",
                                                                      modifiers:
                                                                        {
                                                                          hover: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "ml-2 bx bxs-info-circle text-info",
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer",
                                                                  },
                                                                }),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          qualityCheck.qualityCheckType ===
                                          "OPEN_ENDED"
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-4" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              attrs: {
                                                                for: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "liveImportStep2.form.questionTitle.label"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-danger",
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                              _c("span", [
                                                                _c("i", {
                                                                  directives: [
                                                                    {
                                                                      name: "b-popover",
                                                                      rawName:
                                                                        "v-b-popover.hover",
                                                                      value:
                                                                        _vm.propoverMethodOESQuestion,
                                                                      expression:
                                                                        "propoverMethodOESQuestion",
                                                                      modifiers:
                                                                        {
                                                                          hover: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "ml-2 bx bxs-info-circle text-info",
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer",
                                                                  },
                                                                }),
                                                              ]),
                                                              _c("span", [
                                                                qualityCheck.qualityCheckOESTitle
                                                                  ? _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "badge position-absolute ml-2",
                                                                        class: {
                                                                          "bg-light":
                                                                            qualityCheck
                                                                              .qualityCheckOESTitle
                                                                              .length !==
                                                                            _vm.oesQuestionTitleCharLimit,
                                                                          "bg-danger":
                                                                            qualityCheck
                                                                              .qualityCheckOESTitle
                                                                              .length ===
                                                                            _vm.oesQuestionTitleCharLimit,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              qualityCheck
                                                                                .qualityCheckOESTitle
                                                                                .length
                                                                            ) +
                                                                            " / " +
                                                                            _vm._s(
                                                                              _vm.oesQuestionTitleCharLimit
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  qualityCheck.qualityCheckOESTitle,
                                                                expression:
                                                                  "qualityCheck.qualityCheckOESTitle",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            style: [
                                                              page.isPageSaved
                                                                ? {
                                                                    background:
                                                                      "#f5f5f5",
                                                                  }
                                                                : {
                                                                    background:
                                                                      "none",
                                                                  },
                                                            ],
                                                            attrs: {
                                                              name: "questionIdentifier",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "liveImportStep2.form.questionTitle.placeholder"
                                                                ),
                                                              disabled:
                                                                page.isPageSaved,
                                                            },
                                                            domProps: {
                                                              value:
                                                                qualityCheck.qualityCheckOESTitle,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeQualityCheckOESTitle(
                                                                  pageIndex,
                                                                  qualityCheckIndex
                                                                )
                                                              },
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  qualityCheck,
                                                                  "qualityCheckOESTitle",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          qualityCheck.qualityCheckOESTitleRequiredError
                                                            ? _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "liveImportStep2.form.questionTitle.requiredError"
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-5" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-md-8",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "liveImportStep2.form.keyword.label"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c("span", [
                                                                    _c("i", {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "b-popover",
                                                                            rawName:
                                                                              "v-b-popover.hover",
                                                                            value:
                                                                              _vm.propoverMethodOESQKeywords,
                                                                            expression:
                                                                              "propoverMethodOESQKeywords",
                                                                            modifiers:
                                                                              {
                                                                                hover: true,
                                                                              },
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "ml-2 bx bxs-info-circle text-info",
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                    }),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-md-4 text-right",
                                                                },
                                                                [
                                                                  qualityCheck
                                                                    .qualityCheckOESKeywords
                                                                    .length >
                                                                    0 &&
                                                                  !page.isPageSaved
                                                                    ? _c(
                                                                        "b-badge",
                                                                        {
                                                                          staticClass:
                                                                            "pointer",
                                                                          staticStyle:
                                                                            {
                                                                              "font-size":
                                                                                "12px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "light",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.clickCleanKeywords(
                                                                                  pageIndex,
                                                                                  qualityCheckIndex
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "bx bx-sync pointer mr-1",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "liveImportStep2.form.keyword.reset"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c("vue-tags-input", {
                                                            staticStyle: {
                                                              "max-width":
                                                                "none",
                                                            },
                                                            style: [
                                                              page.isPageSaved ||
                                                              !_vm.isEnableWrongTopicCheck
                                                                ? {
                                                                    background:
                                                                      "#f5f5f5",
                                                                  }
                                                                : {
                                                                    background:
                                                                      "#ffffff",
                                                                  },
                                                            ],
                                                            attrs: {
                                                              disabled:
                                                                page.isPageSaved ||
                                                                !_vm.isEnableWrongTopicCheck,
                                                              tags: qualityCheck.qualityCheckOESKeywords,
                                                              placeholder:
                                                                _vm.$t(
                                                                  "liveImportStep2.form.keyword.placeholder"
                                                                ),
                                                              "max-tags": 15,
                                                            },
                                                            on: {
                                                              "tags-changed": (
                                                                newTags
                                                              ) =>
                                                                _vm.keywordsGetChangeForOES(
                                                                  newTags,
                                                                  pageIndex,
                                                                  qualityCheckIndex
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                qualityCheck.qualityCheckOESKeywordsTags,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    qualityCheck,
                                                                    "qualityCheckOESKeywordsTags",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "qualityCheck.qualityCheckOESKeywordsTags",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-3" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              attrs: {
                                                                for: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "liveImportStep2.form.selectExpectedLanguage.label"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("span", [
                                                                _c("i", {
                                                                  directives: [
                                                                    {
                                                                      name: "b-popover",
                                                                      rawName:
                                                                        "v-b-popover.hover",
                                                                      value:
                                                                        _vm.propoverMethodOESExpectedLanguages,
                                                                      expression:
                                                                        "\n                                propoverMethodOESExpectedLanguages\n                              ",
                                                                      modifiers:
                                                                        {
                                                                          hover: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "ml-2 bx bxs-info-circle text-info",
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer",
                                                                  },
                                                                }),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c("multiselect", {
                                                            attrs: {
                                                              multiple: true,
                                                              options:
                                                                _vm.allLanguages,
                                                              placeholder:
                                                                _vm.$t(
                                                                  "liveImportStep2.form.selectExpectedLanguage.placeholder"
                                                                ),
                                                              "track-by":
                                                                "code",
                                                              searchable: true,
                                                              label: "code",
                                                              "custom-label":
                                                                _vm.customLabelForLanguage,
                                                              "show-labels": false,
                                                              disabled:
                                                                page.isPageSaved,
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.selectLanguagesToCheck,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "singleLabel",
                                                                  fn: function ({
                                                                    option,
                                                                  }) {
                                                                    return [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            option.code
                                                                          ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            option.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: "option",
                                                                  fn: function ({
                                                                    option,
                                                                  }) {
                                                                    return [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            option.code
                                                                          ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            option.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                qualityCheck.qualityCheckOESLanguagesToCheck,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    qualityCheck,
                                                                    "qualityCheckOESLanguagesToCheck",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "\n                            qualityCheck.qualityCheckOESLanguagesToCheck\n                          ",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  page.qualityChecks.length < 2 &&
                                  !page.isPageSaved
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "p-3 mt-3 pointer",
                                          staticStyle: {
                                            border: "1px solid #f5f5f5",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickAddNewQualityCheck(
                                                pageIndex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "bx bx-plus-medical bx-tada",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "liveImportStep2.form.buttons.addDatapoint"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "row mt-3" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12" },
                                      [
                                        !page.isPageSaved
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { variant: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickSavePageButton(
                                                      pageIndex
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bx bxs-save mr-1",
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "liveImportStep2.form.buttons.save"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "row justify-content-center mb-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-block p-3",
                              attrs: {
                                variant: "success",
                                disabled: this.onePageIsOpenForEdit,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickAddNewPage()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "liveImportStep2.form.buttons.addNewPage"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("i", {
                                staticClass: "bx bx-plus-medical bx-tada ml-1",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "fixed-bottom static-buttons mt-2",
                      class: [_vm.isExpanded ? "expanded" : "collapsed"],
                    },
                    [
                      _c("div", { staticClass: "card-body row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-4 text-left" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-2",
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickBackButton()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "mdi mdi-chevron-left",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "liveImportStep2.form.buttons.back"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-md-4 text-center" }, [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "liveImportStep2.creditEstimation.table.total"
                                  )
                                ) +
                                " : " +
                                _vm._s(_vm.maximumCreditsPerInterview) +
                                " "
                            ),
                            _c("i", {
                              staticClass: "bx bxs-info-circle text-info",
                              staticStyle: { cursor: "pointer" },
                              on: { click: _vm.clickCreditInfo },
                            }),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-4 text-right" },
                          [
                            _vm.liveDatafileMetadata.integrationName ===
                            "Forsta Plus"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "mr-3",
                                    attrs: {
                                      href: "https://help.redem.io/docs/data-import/live-import-integrations/forsta-plus-integration/",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "integrationInstructions.general.bestPracticesButton"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "bx bx-link-external ml-1",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.liveDatafileMetadata.integrationName ===
                            "keyingress"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "mr-3",
                                    attrs: {
                                      href: "https://help.redem.io/docs/data-import/live-import-integrations/keyingress-integration/",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "integrationInstructions.general.bestPracticesButton"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "bx bx-link-external ml-1",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.liveDatafileMetadata.integrationName === "NIPO"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "mr-3",
                                    attrs: {
                                      href: "https://help.redem.io/docs/data-import/live-import-integrations/nfield-integration/",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "integrationInstructions.general.bestPracticesButton"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "bx bx-link-external ml-1",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  variant: "primary",
                                  disabled:
                                    this.onePageIsOpenForEdit ||
                                    this.pages.length === 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickNextButton()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "liveImportStep2.form.buttons.complete"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("i", {
                                  staticClass: "mdi mdi-chevron-right",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
      !this.isLoading && this.isSuccess
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 text-center mt-5 mb-5" },
                [
                  _c("i", {
                    staticClass:
                      "bx bx-check-circle bx-tada text-success display-1",
                  }),
                  _c("div", { staticClass: "mt-4 mb-4" }, [
                    _c("h4", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("liveImportStep2.success.title")) +
                          " "
                      ),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("liveImportStep2.success.description")) +
                        " "
                    ),
                  ]),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "warning" },
                      on: {
                        click: function ($event) {
                          return _vm.goToInstructions()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("liveImportStep2.success.button.continue")
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      this.isLoading
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.showCleaningSettingsModal === true
        ? _c("SetCleaningSettingsModal", {
            attrs: { visible: _vm.showCleaningSettingsModal },
            on: {
              close: function ($event) {
                _vm.showCleaningSettingsModal = false
              },
              confirm: _vm.createDraftDatafile,
            },
          })
        : _vm._e(),
      _vm.showCreditInfoModal === true
        ? _c("CreditInfoModal", {
            attrs: { visible: _vm.showCreditInfoModal },
            on: {
              close: function ($event) {
                _vm.showCreditInfoModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }