var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("h6", [
            _c("i", { staticClass: "bx bx-brush mr-2" }),
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("liveImportStep2.setCleaningSettingsModal.title")
                ) +
                " "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "mt-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 mb-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("liveImportStep2.setCleaningSettingsModal.description")
                ) +
                " "
            ),
          ]),
        ]),
        _vm.cleaningSettingsSelectionError
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12 mb-4" },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        show: _vm.cleaningSettingsSelectionError,
                        dismissible: "",
                        variant: "danger",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "liveImportStep2.setCleaningSettingsModal.selectionError"
                          )
                        ) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-1" },
            [
              _c("b-form-checkbox", {
                attrs: {
                  id: "enableCleaningThresholdsCheckBox",
                  name: "check-button",
                  switch: "",
                },
                model: {
                  value: _vm.enableCleaningThreshold,
                  callback: function ($$v) {
                    _vm.enableCleaningThreshold = $$v
                  },
                  expression: "enableCleaningThreshold",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col-md-11" }, [
            _c(
              "label",
              { attrs: { for: "enableCleaningThresholdsCheckBox" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "liveImportStep2.setCleaningSettingsModal.enableCheckBox"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.enableCleaningThreshold,
              expression: "enableCleaningThreshold",
            },
          ],
          staticClass: "mt-4",
        },
        [
          _c("div", { staticClass: "row align-items-center ml-1 mb-2" }, [
            _c("div", { staticClass: "col-md-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.cleaningSettings.R.isEnable,
                    expression: "cleaningSettings.R.isEnable",
                  },
                ],
                staticClass: "text-primary",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.cleaningSettings.R.isEnable)
                    ? _vm._i(_vm.cleaningSettings.R.isEnable, null) > -1
                    : _vm.cleaningSettings.R.isEnable,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.cleaningSettings.R.isEnable,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.cleaningSettings.R,
                            "isEnable",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.cleaningSettings.R,
                            "isEnable",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.cleaningSettings.R, "isEnable", $$c)
                    }
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col-md-7" }, [
              _vm._v("ReDem®-Score (R-Score)"),
            ]),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                !_vm.cleaningSettings.R.isEnable
                  ? _c("b-form-input", {
                      staticStyle: {
                        background: "#f5f5f5",
                        "-webkit-appearance": "none",
                        "-moz-appearance": "none",
                      },
                      attrs: {
                        disabled: "",
                        placeholder: _vm.$t(
                          "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.cleaningSettings.R.threshold,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.cleaningSettings.R,
                            "threshold",
                            _vm._n($$v)
                          )
                        },
                        expression: "cleaningSettings.R.threshold",
                      },
                    })
                  : _c("b-form-input", {
                      attrs: {
                        type: "number",
                        min: 0,
                        max: 100,
                        placeholder: _vm.$t(
                          "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                        ),
                        formatter: _vm.formatScore,
                      },
                      model: {
                        value: _vm.cleaningSettings.R.threshold,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.cleaningSettings.R,
                            "threshold",
                            _vm._n($$v)
                          )
                        },
                        expression: "cleaningSettings.R.threshold",
                      },
                    }),
              ],
              1
            ),
          ]),
          _vm.cleaningSettings.OES.isDisplay
            ? _c("div", { staticClass: "row align-items-center ml-1 mb-2" }, [
                _c("div", { staticClass: "col-md-1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cleaningSettings.OES.isEnable,
                        expression: "cleaningSettings.OES.isEnable",
                      },
                    ],
                    staticClass: "text-primary",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.cleaningSettings.OES.isEnable)
                        ? _vm._i(_vm.cleaningSettings.OES.isEnable, null) > -1
                        : _vm.cleaningSettings.OES.isEnable,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.cleaningSettings.OES.isEnable,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cleaningSettings.OES,
                                "isEnable",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cleaningSettings.OES,
                                "isEnable",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.cleaningSettings.OES, "isEnable", $$c)
                        }
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "col-md-7" }, [
                  _vm._v("Open-Ended-Score (OES)"),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    !_vm.cleaningSettings.OES.isEnable
                      ? _c("b-form-input", {
                          staticStyle: {
                            background: "#f5f5f5",
                            "-webkit-appearance": "none",
                            "-moz-appearance": "none",
                          },
                          attrs: {
                            disabled: "",
                            placeholder: _vm.$t(
                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                            ),
                          },
                          model: {
                            value: _vm.cleaningSettings.OES.threshold,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cleaningSettings.OES,
                                "threshold",
                                _vm._n($$v)
                              )
                            },
                            expression: "cleaningSettings.OES.threshold",
                          },
                        })
                      : _c("b-form-input", {
                          attrs: {
                            type: "number",
                            min: 0,
                            max: 100,
                            placeholder: _vm.$t(
                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                            ),
                            formatter: _vm.formatScore,
                          },
                          model: {
                            value: _vm.cleaningSettings.OES.threshold,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cleaningSettings.OES,
                                "threshold",
                                _vm._n($$v)
                              )
                            },
                            expression: "cleaningSettings.OES.threshold",
                          },
                        }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.cleaningSettings.TS.isDisplay
            ? _c("div", { staticClass: "row align-items-center ml-1 mb-2" }, [
                _c("div", { staticClass: "col-md-1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cleaningSettings.TS.isEnable,
                        expression: "cleaningSettings.TS.isEnable",
                      },
                    ],
                    staticClass: "text-primary",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.cleaningSettings.TS.isEnable)
                        ? _vm._i(_vm.cleaningSettings.TS.isEnable, null) > -1
                        : _vm.cleaningSettings.TS.isEnable,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.cleaningSettings.TS.isEnable,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cleaningSettings.TS,
                                "isEnable",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cleaningSettings.TS,
                                "isEnable",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.cleaningSettings.TS, "isEnable", $$c)
                        }
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "col-md-7" }, [
                  _vm._v("Time-Score (TS)"),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    !_vm.cleaningSettings.TS.isEnable
                      ? _c("b-form-input", {
                          staticStyle: {
                            background: "#f5f5f5",
                            "-webkit-appearance": "none",
                            "-moz-appearance": "none",
                          },
                          attrs: {
                            disabled: "",
                            placeholder: _vm.$t(
                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                            ),
                          },
                          model: {
                            value: _vm.cleaningSettings.TS.threshold,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cleaningSettings.TS,
                                "threshold",
                                _vm._n($$v)
                              )
                            },
                            expression: "cleaningSettings.TS.threshold",
                          },
                        })
                      : _c("b-form-input", {
                          attrs: {
                            type: "number",
                            min: 0,
                            max: 100,
                            placeholder: _vm.$t(
                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                            ),
                            formatter: _vm.formatScore,
                          },
                          model: {
                            value: _vm.cleaningSettings.TS.threshold,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cleaningSettings.TS,
                                "threshold",
                                _vm._n($$v)
                              )
                            },
                            expression: "cleaningSettings.TS.threshold",
                          },
                        }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.cleaningSettings.IBS.isDisplay
            ? _c("div", { staticClass: "row align-items-center ml-1 mb-2" }, [
                _c("div", { staticClass: "col-md-1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cleaningSettings.IBS.isEnable,
                        expression: "cleaningSettings.IBS.isEnable",
                      },
                    ],
                    staticClass: "text-primary",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.cleaningSettings.IBS.isEnable)
                        ? _vm._i(_vm.cleaningSettings.IBS.isEnable, null) > -1
                        : _vm.cleaningSettings.IBS.isEnable,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.cleaningSettings.IBS.isEnable,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cleaningSettings.IBS,
                                "isEnable",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cleaningSettings.IBS,
                                "isEnable",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.cleaningSettings.IBS, "isEnable", $$c)
                        }
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "col-md-7" }, [
                  _vm._v("Grid-Question-Score (GQS)"),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    !_vm.cleaningSettings.IBS.isEnable
                      ? _c("b-form-input", {
                          staticStyle: {
                            background: "#f5f5f5",
                            "-webkit-appearance": "none",
                            "-moz-appearance": "none",
                          },
                          attrs: {
                            disabled: "",
                            placeholder: _vm.$t(
                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                            ),
                          },
                          model: {
                            value: _vm.cleaningSettings.IBS.threshold,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cleaningSettings.IBS,
                                "threshold",
                                _vm._n($$v)
                              )
                            },
                            expression: "cleaningSettings.IBS.threshold",
                          },
                        })
                      : _c("b-form-input", {
                          attrs: {
                            type: "number",
                            min: 0,
                            max: 100,
                            placeholder: _vm.$t(
                              "datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder"
                            ),
                            formatter: _vm.formatScore,
                          },
                          model: {
                            value: _vm.cleaningSettings.IBS.threshold,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.cleaningSettings.IBS,
                                "threshold",
                                _vm._n($$v)
                              )
                            },
                            expression: "cleaningSettings.IBS.threshold",
                          },
                        }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _vm.enableCleaningThreshold
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.clickConfirm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "liveImportStep2.setCleaningSettingsModal.button.enebleCleaning"
                        )
                      ) + " "
                    ),
                  ]
                )
              : _vm._e(),
            !_vm.enableCleaningThreshold
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.clickConfirm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "liveImportStep2.setCleaningSettingsModal.button.noCleaning"
                        )
                      ) + " "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "light" },
                on: {
                  click: function ($event) {
                    return _vm.clickCancel()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "liveImportStep2.setCleaningSettingsModal.button.cancel"
                    )
                  ) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }