var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "", "hide-header": "" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8" }, [
          _c("h5", [
            _c("i", {
              staticClass: "bx bx-money h4 text-warning bx-tada mr-1",
            }),
            _vm._v(
              " " +
                _vm._s(_vm.$t("liveImportStep2.creditEstimation.title")) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-md-4 text-right" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://help.redem.io/docs/data-import/redem-credits/",
                target: "_blank",
              },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("liveImportStep2.creditEstimation.learnMore")) +
                  " "
              ),
              _c("i", { staticClass: "bx bx-link-external ml-1" }),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          this.displayCreditTable && !this.isLoading
            ? _c("div", { staticClass: "table-responsive mt-3" }, [
                _c("table", { staticClass: "table mt-3 mb-3" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "liveImportStep2.creditEstimation.table.header.designation"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("th", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "liveImportStep2.creditEstimation.table.header.quantity"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "liveImportStep2.creditEstimation.table.header.credits"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(
                        _vm.dataForTable,
                        function (qualityScore, qualityScoreIndex) {
                          return _c("tr", { key: qualityScoreIndex }, [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  qualityScore.qualityScore === "IBS"
                                    ? "GQS"
                                    : qualityScore.qualityScore
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(qualityScore.numberOfDataPoints) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(qualityScore.credits)),
                            ]),
                          ])
                        }
                      ),
                      _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "liveImportStep2.creditEstimation.table.total"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("td", { staticClass: "text-right" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.creditObject.maximumCreditAmount)
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("liveImportStep2.creditEstimation.description")) +
              " "
          ),
          _c("div", { staticClass: "mt-2" }, [
            _c("ol", [
              _c("li", [
                _vm._v(
                  _vm._s(_vm.$t("liveImportStep2.creditEstimation.point1"))
                ),
              ]),
              _c("li", [
                _vm._v(
                  _vm._s(_vm.$t("liveImportStep2.creditEstimation.point2"))
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }