<script>
import ColumnTypes from "@/shared/enums/columnTypes.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean
  },
  data() {
    return {
      creditAmount: 0,
      enableCleaningThreshold: false,
      allQuestions: [],
      startingQuestion: null,
      columnTypes: ColumnTypes,
      cleaningSettings: {
        R: {
          isDisplay: true,
          key: "R",
          name: "R Score",
          isEnable: false,
          threshold: 60
        },
        TS: {
          isDisplay: false,
          key: "TS",
          name: "Time Score",
          isEnable: false,
          threshold: 20
        },
        OES: {
          isDisplay: false,
          key: "OES",
          name: "Open Ended Score",
          isEnable: false,
          threshold: 30
        },
        IBS: {
          isDisplay: false,
          key: "IBS",
          name: "Item Battery Score",
          isEnable: false,
          threshold: 0
        }
      }, 
      cleaningSettingsSelectionError: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async mounted() {
    this.allQuestions = [];

    this.creditAmount = localStorage.getItem(
      "maximumCreditAmountForRespondent"
    );

    // get questions from local storage
    this.pages = JSON.parse(localStorage.getItem("pages"));
    if (this.pages && this.pages.length) {
      for (let i = 0; i < this.pages.length; i++) {
        for (let q = 0; q < this.pages[i].qualityChecks.length; q++) {
          this.allQuestions.push(this.pages[i].qualityChecks[q]);
        }
      }
    }

    // get starting questions from local storage
    this.enableTotalSurveyDuration = JSON.parse(
      localStorage.getItem("enableTotalSurveyDuration")
    );
    if (this.enableTotalSurveyDuration) {
      this.cleaningSettings.TS.isDisplay = true;
    }

    // get cleaning setting saved on localstorage and set
    const savedCleaningSettings = JSON.parse(
      localStorage.getItem("cleaningSettings")
    );
    if (savedCleaningSettings) this.cleaningSettings = savedCleaningSettings;

    // get cleaning setting saved on localstorage and set
    this.enableCleaningThreshold = JSON.parse(
      localStorage.getItem("enableCleaningThreshold")
    );

    await this.setSelectedQualityScoreTypes();
  },
  methods: {
    clickCancel() {
      this.$emit("close");
    },
    validateCleaningSelections() {
      let numberOfQualityChekcApplyForCleaning = 0

      for (const qualityCheck in this.cleaningSettings) {
        if(this.cleaningSettings[qualityCheck].isEnable) numberOfQualityChekcApplyForCleaning += 1
      }

      if(numberOfQualityChekcApplyForCleaning === 0 ) {
        return false
      } else {
        return true
      }
    },
    async clickConfirm() {
      let isCleaningSettingsValid = await this.validateCleaningSelections()

      if(this.enableCleaningThreshold && !isCleaningSettingsValid) {
        this.cleaningSettingsSelectionError = true
      } else {
        let cleaningSettingsObject = this.cleaningSettings;

        delete cleaningSettingsObject.R.isDisplay;
        delete cleaningSettingsObject.OES.isDisplay;
        delete cleaningSettingsObject.TS.isDisplay;
        delete cleaningSettingsObject.IBS.isDisplay;

        // set items to local storage
        localStorage.setItem(
          "cleaningSettings",
          JSON.stringify(cleaningSettingsObject)
        );

        localStorage.setItem(
          "enableCleaningThreshold",
          JSON.stringify(this.enableCleaningThreshold)
        );

        this.$emit("confirm");
      }
      
    },
    setSelectedQualityScoreTypes() {
      if (this.allQuestions && this.allQuestions.length > 0) {
        for (let i = 0; i < this.allQuestions.length; i++) {
          if (
            this.allQuestions[i].qualityCheckType ===
              this.columnTypes.TIMESTAMP ||
            this.allQuestions[i].isTimeScoreEnable
          )
            this.cleaningSettings.TS.isDisplay = true;

          if (
            this.allQuestions[i].qualityCheckType ===
            this.columnTypes.OPEN_ENDED
          )
            this.cleaningSettings.OES.isDisplay = true;

          if (
            this.allQuestions[i].qualityCheckType ===
            this.columnTypes.ITEMBATTERY
          )
            this.cleaningSettings.IBS.isDisplay = true;
        }
      }
    },
    formatScore(value) {
      if (!value || value === undefined) return 0;
      if (value > 100) return 100;
      if (value < 0) return 0;
      return value;
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <!-- Title-->
    <div class="row">
      <div class="col-md-12">
        <h6>
          <i class="bx bx-brush mr-2"></i>
          {{ $t("liveImportStep2.setCleaningSettingsModal.title") }}
        </h6>
      </div>
    </div>

    <!-- Cleaning Thresholds Check box -->
    <div class="mt-3">
      <div class="row">
        <div class="col-md-12 mb-4">
          {{ $t("liveImportStep2.setCleaningSettingsModal.description") }}
        </div>
      </div>

      <div class="row" v-if="cleaningSettingsSelectionError">
        <div class="col-md-12 mb-4">
          <b-alert
            :show="cleaningSettingsSelectionError"
            dismissible
            class="mt-2"
            variant="danger"
            >{{ $t("liveImportStep2.setCleaningSettingsModal.selectionError") }}
          </b-alert>
        </div>
      </div>

      <div class="row">
        <div class="col-md-1">
          <b-form-checkbox
            v-model="enableCleaningThreshold"
            id="enableCleaningThresholdsCheckBox"
            name="check-button"
            switch
          />
        </div>
        <div class="col-md-11">
          <label for="enableCleaningThresholdsCheckBox">
            {{ $t("liveImportStep2.setCleaningSettingsModal.enableCheckBox") }}
          </label>
        </div>
      </div>
    </div>

    <!-- Cleaning Threshold -->
    <div class="mt-4" v-show="enableCleaningThreshold">
      <!-- Redem Score -->
      <div class="row align-items-center ml-1 mb-2">
        <div class="col-md-1">
          <input
            v-model="cleaningSettings.R.isEnable"
            class="text-primary"
            type="checkbox"
          />
        </div>
        <div class="col-md-7">ReDem®-Score (R-Score)</div>
        <div class="col-md-4">
          <b-form-input
            v-if="!cleaningSettings.R.isEnable"
            v-model.number="cleaningSettings.R.threshold"
            disabled
            style="
              background: #f5f5f5;
              -webkit-appearance: none;
              -moz-appearance: none;
            "
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
          >
          </b-form-input>
          <b-form-input
            v-else
            v-model.number="cleaningSettings.R.threshold"
            type="number"
            :min="0"
            :max="100"
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
            :formatter="formatScore"
          >
          </b-form-input>
        </div>
      </div>

      <!-- OES -->
      <div
        class="row align-items-center ml-1 mb-2"
        v-if="cleaningSettings.OES.isDisplay"
      >
        <div class="col-md-1">
          <input
            class="text-primary"
            type="checkbox"
            v-model="cleaningSettings.OES.isEnable"
          />
        </div>
        <div class="col-md-7">Open-Ended-Score (OES)</div>
        <div class="col-md-4">
          <b-form-input
            v-if="!cleaningSettings.OES.isEnable"
            v-model.number="cleaningSettings.OES.threshold"
            disabled
            style="
              background: #f5f5f5;
              -webkit-appearance: none;
              -moz-appearance: none;
            "
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
          >
          </b-form-input>
          <b-form-input
            v-else
            v-model.number="cleaningSettings.OES.threshold"
            type="number"
            :min="0"
            :max="100"
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
            :formatter="formatScore"
          >
          </b-form-input>
        </div>
      </div>

      <!-- TS-->
      <div
        class="row align-items-center ml-1 mb-2"
        v-if="cleaningSettings.TS.isDisplay"
      >
        <div class="col-md-1">
          <input
            class="text-primary"
            type="checkbox"
            v-model="cleaningSettings.TS.isEnable"
          />
        </div>
        <div class="col-md-7">Time-Score (TS)</div>
        <div class="col-md-4">
          <b-form-input
            v-if="!cleaningSettings.TS.isEnable"
            v-model.number="cleaningSettings.TS.threshold"
            disabled
            style="
              background: #f5f5f5;
              -webkit-appearance: none;
              -moz-appearance: none;
            "
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
          >
          </b-form-input>
          <b-form-input
            v-else
            type="number"
            v-model.number="cleaningSettings.TS.threshold"
            :min="0"
            :max="100"
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
            :formatter="formatScore"
          >
          </b-form-input>
        </div>
      </div>

      <!-- IBS -->
      <div
        class="row align-items-center ml-1 mb-2"
        v-if="cleaningSettings.IBS.isDisplay"
      >
        <div class="col-md-1">
          <input
            class="text-primary"
            type="checkbox"
            v-model="cleaningSettings.IBS.isEnable"
          />
        </div>
        <div class="col-md-7">Grid-Question-Score (GQS)</div>
        <div class="col-md-4">
          <b-form-input
            v-if="!cleaningSettings.IBS.isEnable"
            v-model.number="cleaningSettings.IBS.threshold"
            disabled
            style="
              background: #f5f5f5;
              -webkit-appearance: none;
              -moz-appearance: none;
            "
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
          >
          </b-form-input>
          <b-form-input
            v-else
            v-model.number="cleaningSettings.IBS.threshold"
            type="number"
            :min="0"
            :max="100"
            :placeholder="
              $t(
                'datafileResultGeneral.cleanAndMarkModal.cleaningOption1.placeholder'
              )
            "
            :formatter="formatScore"
          >
          </b-form-input>
        </div>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="row mt-4">
      <div class="col-md-12"> 
        <b-button variant="success" @click="clickConfirm()" v-if="enableCleaningThreshold"
          >{{ $t("liveImportStep2.setCleaningSettingsModal.button.enebleCleaning") }}
        </b-button>
        <b-button variant="success" @click="clickConfirm()" v-if="!enableCleaningThreshold"
          >{{ $t("liveImportStep2.setCleaningSettingsModal.button.noCleaning") }}
        </b-button>
        <b-button class="ml-2" variant="light" @click="clickCancel()"
          >{{ $t("liveImportStep2.setCleaningSettingsModal.button.cancel") }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
